import { useAppData } from "src/context/useAppData"
import { authTokenStorage, clearAuthStorage } from "src/data/auth/authStorage"
import { useOrganizationQueryId } from "src/data/organizations/hooks/useOrganizationQueryId"
import { useBackendLogout } from "src/data/user/queries/userAuthQueries"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { useFlags } from "src/hooks/useFlags"
import { useUrlParam, useUrlParamBoolean } from "src/hooks/useUrlParam"
import { Routes } from "src/router/routes"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { debug, logger } from "src/utils/logger"

export function Logout() {
  const { oauthEnabled, logoutDebugger } = useFlags()
  const { clear: clearAppData } = useAppData()
  const { reset: resetFlags } = useFlags()
  const { replaceOrgIdParam } = useOrganizationQueryId()
  const { value: logoutReason } = useUrlParam("reason")
  const { value: userInitiated } = useUrlParamBoolean("userInitiated")
  const { logout: logoutBackend } = useBackendLogout()

  function clearLoginData() {
    debug.log("clear login data")
    clearAppData()
    clearAuthStorage()
    resetFlags()
    replaceOrgIdParam(undefined)
  }

  function postLogout() {
    clearLoginData()
    if (oauthEnabled) {
      // Only trigger a backend logout if the user initiated the logout, since that makes it
      // clear it was their intention to logout. If on the other hand a unknown state in the
      // webapp causes this logout to happen, we don't want to clear any potentially authenticated
      // session with the backend, for example when the user first arrives in the webapp after
      // having reset their password and then being redirected to the webapp.
      if (userInitiated) {
        window.location.href = Routes.BELogout.location().pathname
      } else {
        window.location.href = Routes.Login.location().pathname
      }
      return
    } else {
      window.location.href = Routes.Login.location().pathname
    }
  }

  useEffectOnce(() => {
    logger.warn("Logging out", { reason: logoutReason })
    if (logoutDebugger) {
      // eslint-disable-next-line no-debugger
      debugger
    }
    const token = authTokenStorage.get()?.access_token
    if (token) {
      logoutBackend(token).finally(() => {
        postLogout()
      })
    } else {
      postLogout()
    }
  })
  return <LoadingScreen debugInfo={"Logging out"} />
}
